
import { Iimage } from '@/interfaces/Iimage'
import { DataGallery } from '@/data/DataGallery'
import ImageView from '@/components/Gallery/ImageView.vue'

export default {
  name: 'Gallery',
  components: { ImageView },
  computed: {
    gallery (): Iimage[] { return DataGallery }
  }
}
