<template>
  <div class="gallery">
    <div class="content">
      <div class="imageslist">
        <ImageView v-for="img in gallery" :key="img.name" :image="img"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Iimage } from '@/interfaces/Iimage'
import { DataGallery } from '@/data/DataGallery'
import ImageView from '@/components/Gallery/ImageView.vue'

export default {
  name: 'Gallery',
  components: { ImageView },
  computed: {
    gallery (): Iimage[] { return DataGallery }
  }
}
</script>
