
import { Iimage } from '@/interfaces/Iimage'
import { defineComponent, PropType } from 'vue'
import Vuex from 'vuex'

export default defineComponent({
  name: 'ImageView',
  data () {
    return {
      link: '',
      loaded: false
    }
  },
  props: {
    image: { type: Object as PropType<Iimage>, required: true }
  },
  methods: {
    ...Vuex.mapActions({
      set: 'setImageVisualizer'
    }),
    click (): void {
      this.set(this.image)
    }
  },
  mounted () {
    import('@/assets/gallery/' + this.image.link).then((data) => {
      this.link = data.default
      this.loaded = true
    })
  }
})
