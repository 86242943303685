<template>
  <div class="head">
    <video class="back" autoplay muted loop>
      <source src="@/assets/video/Cine4.mp4">
    </video>
    <video autoplay muted loop @click="videoClick" ref="video" title="Click for unmute/mute">
      <source src="@/assets/video/Cine4.mp4">
    </video>
<!--    <img src="@/assets/Images.png" alt="Sadness of Valor Title with tank cannon fired">
    <div class="texts">
      <p>Build, Manage and Fight!</p>
      <p>Commanding officer !</p>
      <p>Release estimated end 2022</p>
    </div>-->
  </div>
</template>

<script>
/* eslint-disable */
// Reup
export default {
  name: 'Head',
  methods: {
    videoClick: (e) => {
      if (e.target) e.target.muted = !e.target.muted
    }
  }
}
</script>

<style scoped>

</style>
