<template>
  <div class="imageVisualizer" :class="{enable:imageVisualizerEnable}" @click="click">
    <div class="image">
      <img :src="imageLink" :alt="imageVisualizer?.name">
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ImageVisualizer',
  data () {
    return {
      imageLink: ''
    }
  },
  computed: {
    ...Vuex.mapGetters({
      imageVisualizer: 'imageVisualizer',
      imageVisualizerEnable: 'imageVisualizerEnable'
    })
  },
  methods: {
    ...Vuex.mapActions({
      reset: 'resetImageVisualizer'
    }),
    click () {
      this.reset()
    }
  },
  watch: {
    imageVisualizer () {
      if (this.imageVisualizer) {
        import('@/assets/gallery/' + this.imageVisualizer.link).then((data) => {
          this.imageLink = data.default
        })
      } else this.imageLink = ''
    }
  }
})
</script>
