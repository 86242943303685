import { Iimage } from '@/interfaces/Iimage'

export const DataGallery: Iimage[] = [
  { name: 'PrintScreen_0000_Calque-16', link: 'PrintScreen_0000_Calque-16.png' },
  { name: 'PrintScreen_0000_Calque-18', link: 'PrintScreen_0000_Calque-18.png' },
  { name: 'PrintScreen_0001_Calque-15', link: 'PrintScreen_0001_Calque-15.png' },
  { name: 'PrintScreen_0001_Calque-17', link: 'PrintScreen_0001_Calque-17.png' },
  { name: 'PrintScreen_0002_Calque-14', link: 'PrintScreen_0002_Calque-14.png' },
  { name: 'PrintScreen_0003_Calque-13', link: 'PrintScreen_0003_Calque-13.png' },
  { name: 'PrintScreen_0004_Calque-12', link: 'PrintScreen_0004_Calque-12.png' },
  { name: 'PrintScreen_0005_Calque-11', link: 'PrintScreen_0005_Calque-11.png' },
  { name: 'PrintScreen_0006_Calque-10', link: 'PrintScreen_0006_Calque-10.png' },
  { name: 'PrintScreen_0007_Calque-9', link: 'PrintScreen_0007_Calque-9.png' },
  { name: 'PrintScreen_0008_Calque-8', link: 'PrintScreen_0008_Calque-8.png' },
  { name: 'PrintScreen_0009_Calque-7', link: 'PrintScreen_0009_Calque-7.png' }
]
