<template>
  <footer>
    <ul class="onemanstudioforgamesLogo">
      <li><a href="https://onemanstudioforgames.com"><img src="@/assets/onemanstudioforgames_logo.svg" alt="One Man Studio For Games"></a></li>
    </ul>
    <ul>
      <li><a href="https://store.steampowered.com/app/1647860"><i class="fab fa-steam"></i></a></li>
    </ul>
    <h5>Follow Slordef</h5>
    <ul class="socials">
      <li><a href="https://www.facebook.com/Slordefofficialpage/"><i class="fab fa-facebook"></i></a></li>
      <li><a href="https://twitter.com/slordef"><i class="fab fa-twitter"></i></a></li>
      <li><a href="https://www.youtube.com/user/Slordef"><i class="fab fa-youtube"></i></a></li>
      <li><a href="https://discord.gg/xx5kuQj5Qh"><i class="fab fa-discord"></i></a></li>
    </ul>
    <div class="signature">
      &copy;2021 <a href="https://slordef-webmaster.ch/">Slordef</a>, all right reserved
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>

</style>
