<template>
  <section class="Home">
    <!--suppress HtmlRequiredTitleElement -->
    <Head />
    <article class="black">
      <h2>Trailer</h2>
      <video controls>
        <source src="@/assets/video/Cine4.mp4">
      </video>
    </article>
    <article>
      <p>
        A created game by Slordef in One Man Studio For Games. <br>
        Real Time Strategy game, based on management and positioning of units.
        The scarce resources force the player to find a way to fight effectively.
        Fire can be dodged to land on enemy or allied units, friendly fire is dangerous.
      </p>
      <p>Find Sadness of Valor on Steam :</p>
      <p><a href="https://store.steampowered.com/app/1647860">Game Steam page</a></p>
    </article>
    <article class="description">
      <p>Commander aboard the Global Support Orbital Station (GSOS-3) capable of intervening rapidly on a part of the planet, ensuring the sustainability of humanity.
        Nothing ever goes as planned.
        Face the chaotic desert left by the planet and discover the mysteries of its history.

        In this RTS, alternate between management and combat with friends or soon on a machmaking!</p>
    </article>
    <Gallery />
  </section>
</template>

<script>
import Head from '@/components/Head'
import Gallery from '@/components/Gallery'
export default {
  name: 'Home',
  components: { Gallery, Head }
}
</script>
