import { Iimage } from '@/interfaces/Iimage'
import { createStore } from 'vuex'

export default createStore({
  state: {
    imageVisualizer: null as null | Iimage,
    imageVisualizerEnable: false
  },
  getters: {
    imageVisualizer (state): null | Iimage { return state.imageVisualizer },
    imageVisualizerEnable (state): boolean { return state.imageVisualizerEnable }
  },
  mutations: {
    changeImageVisualizer (state, data: null | Iimage): void {
      const valid = !!data
      if (valid) state.imageVisualizer = data
      state.imageVisualizerEnable = valid
    }
  },
  actions: {
    resetImageVisualizer (context): void { context.commit('changeImageVisualizer', null) },
    setImageVisualizer (context, image: Iimage): void { context.commit('changeImageVisualizer', image) }
  },
  modules: {
  }
})
